export class RevSlider extends elementorModules.frontend.handlers.Base {
  getDefaultSettings() {
    return {
      selectors: {
        slider: '.revslider',
      },
    };
  }

  getDefaultElements() {
    const selectors = this.getSettings('selectors');
    return {
      $slider: this.$element.find(selectors.slider),
    };
  }

  bindEvents() {
    this.init();
  }

  init() {
    const slider = this.elements.$slider[0];
    window.sliderLeftInstances = [];
    window.sliderRightInstances = [];

    this.handleIfMobile();
    window.addEventListener('resize', () => {
      this.handleIfMobile();
    });

    const swiperLeftConfig = {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      // autoplay: {
      //     delay: 5000,
      //     disableOnInteraction: false,
      // },
      pagination: {
        el: '.revslider__pagination',
        clickable: true,
      },
    };

    const swiperRightConfig = {
      loop: true,
      effect: 'fade',
      speed: 0,
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
    };

    const leftInstance = slider.querySelector('.revslider__left');
    const rightInstance = slider.querySelector('.revslider__right');

    let swiperLeft = null;
    let swiperRight = null;
    if (typeof Swiper === 'undefined') {
      const asyncSwiper = elementorFrontend.utils.swiper;
      Promise.all([
        new asyncSwiper(leftInstance, swiperLeftConfig),
        new asyncSwiper(rightInstance, swiperRightConfig),
      ]).then(values => {
        swiperLeft = values[0];
        swiperRight = values[1];
        this.afterInitSliders(swiperLeft, swiperRight);
      });
    } else {
      swiperLeft = new Swiper(leftInstance, swiperLeftConfig);
      swiperRight = new Swiper(rightInstance, swiperRightConfig);
      this.afterInitSliders(swiperLeft, swiperRight);
    }
  }

  afterInitSliders(swiperLeft, swiperRight) {
    const slider = this.elements.$slider[0];
    window.sliderLeftInstances.push(swiperLeft);
    window.sliderRightInstances.push(swiperRight);

    // Detect when the left slider moves and move the right slider to the corresponding slide
    swiperLeft.on('slideChange', function () {
      swiperRight.slideTo(swiperLeft.realIndex + 1, 300, false);
    });

    // Apply a subtle delay to the right slider when the left slide changes
    // swiperLeft.on('slideChangeTransitionStart', function () {
    //   swiperRight.setTransition(300);
    // });

    // Add navigation
    const next = slider.querySelector('.revslider__next');
    next &&
      next.addEventListener('click', ev => {
        ev.preventDefault();
        swiperLeft.slideNext();
      });

    const prev = slider.querySelector('.revslider__prev');
    prev &&
      prev.addEventListener('click', ev => {
        ev.preventDefault();
        swiperLeft.slidePrev();
      });
  }

  handleIfMobile() {
    const pagination = this.elements.$slider.find('.revslider__pagination');
    const revSliderRight = this.elements.$slider.find('.revslider__right');
    const revSliderPrev = this.elements.$slider.find('.revslider__prev');

    var isMobile = window.isMobile;
    if (isMobile) {
      // Move pagination after revSliderPrev
      revSliderPrev.after(pagination);
    } else {
      // Move pagination at the end of revSliderRight
      revSliderRight.append(pagination);
    }
  }
}
