export class RevAccordion extends elementorModules.frontend.handlers.Base {
  getDefaultSettings() {
    return {
      selectors: {
        accordion: '.revaccordion',
      },
    };
  }

  getDefaultElements() {
    const selectors = this.getSettings('selectors');
    return {
      $accordion: this.$element.find(selectors.accordion),
    };
  }

  bindEvents() {
    //this.elements.$slider.on('click', () => {});
    this.init();
  }

  init() {
    const accordion = this.elements.$accordion[0];
    const accordionItems = accordion.querySelectorAll('.revaccordion__item');
    accordionItems &&
      accordionItems.forEach(function (item) {
        item.addEventListener('click', function (event) {
          // Check if the target is an anchor
          if (event.target.tagName === 'A') {
            return;
          }

          // Close other items
          accordionItems.forEach(function (acc) {
            acc !== item && acc.classList.remove('revaccordion__item--active');
          });

          // Comment this to close the accordion items only on mobile
          item.classList.toggle('revaccordion__item--active');

          // Uncomment this to close the accordion items only on mobile
          // window.isMobile
          //   ? item.classList.toggle('revaccordion__item--active')
          //   : item.classList.add('revaccordion__item--active');

          const parent = item.closest('.revaccordion');
          parent.dataset.expanded = document.querySelector(
            '.revaccordion__item--active'
          )
            ? 'true'
            : 'false';
        });
      });
  }
}
