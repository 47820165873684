import { initGlobals } from './modules/helpers.js';
import { RevAccordion } from './modules/accordion.js';
import { RevSlider } from './modules/slider.js';

document.addEventListener('DOMContentLoaded', function () {
  initGlobals();
});

jQuery(window).on('elementor/frontend/init', () => {
  elementorFrontend.elementsHandler.attachHandler('revoro-slider', RevSlider);

  elementorFrontend.elementsHandler.attachHandler(
    'revoro-accordion',
    RevAccordion
  );
});
