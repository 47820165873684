function initGlobals() {
    checkIfMobile();
    initWindowResize();
}

function checkIfMobile() {
    const isMobile = Boolean(window.innerWidth < 768);
    window.isMobile =
      isMobile ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    return isMobile;
}

function initWindowResize() {
    window.addEventListener('resize', async function () {
        checkIfMobile();
    });
}

export {
    checkIfMobile,
    initWindowResize,
    initGlobals
};
